import ReactGA from "react-ga4"
import ReactPixel from "react-facebook-pixel"
// import ReactSnapChatPixel from "react-snapchat-pixel"
import constants from "../constants"
import axios from "axios"

export const initPixelConvTrkr = () => {
  ReactPixel.init(constants.PIXEL_TRACKING_ID)
  ReactPixel.init(constants.PIXEL_TRACKING_ID_2)
  ReactPixel.init(constants.PIXEL_TRACKING_ID_3)
  // ReactSnapChatPixel.init(constants.PIXEL_SNAPCHAT_TRACKING_ID)
}

export const logPixelPageView = () => {
  ReactPixel.pageView()
  // ReactSnapChatPixel.pageView()
}

export const initGA = () => {
  ReactGA.initialize(constants.GA_TRACKING_ID)
  // ReactGA.initialize(constants.GA_TRACKING_ID_2)
}

export const gTag = () => {
// TagManager.initialize(constants.GTM_TRACKING_ID)
}


export const logPageView = () => {
  // ReactGA.set({ page: window.location.pathname })
  // ReactGA.pageview(window.location.pathname)
}

export const logEvent = (event = "") => {
  if (event === constants.PIXEL_EVENT.PUCHASE) {
    ReactPixel.track(constants.PIXEL_EVENT.PUCHASE, {
      value: 1,
      currency: "INR",
      content_category: "Purchased",
    })
    // ReactSnapChatPixel.track("PURCHASE", {
    //   currency: "INR",
    //   price: amount,
    //   item_category: categoryCode,
    //   transaction_id: transactionId,
    // })
  } else {
    ReactPixel.track(constants.PIXEL_EVENT.LEAD, {
      content_category: "Lead",
    })
    // ReactSnapChatPixel.track("START_CHECKOUT")
  }
}

// export const logException = (description = "", fatal = false) => {
//   if (description) {
//     ReactGA.exception({ description, fatal });
//   }
// };

export const completeRegistrationEvent = async(user:{name:string,email:string,phone:string})=>{
try{
await axios.post(`${constants.OLD_DOMAIN}/api/v1/users/facebook/triggerCompleteRegistration`,{...user})
}catch(error){
  console.log('Event Log Failed');
}
}