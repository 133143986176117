import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { useAuth } from "../Context/authContext";

interface Props {
  children: React.ReactNode;
}

const AuthChecker = ({ children }: Props) => {
  const navigate = useNavigate();
  const {currentUser } = useAuth()
  const referredWebsite = btoa(window.location.pathname + window.location.search)
  useEffect(() => {
    if (!currentUser) {
      navigate(`/signin?re=${referredWebsite}`);
    }
  }, [currentUser]); 

  return <>{children}</>;
};

export default AuthChecker;