import Image from 'Assets/index'
import React from 'react'

function Loader() {
  return (
    <section>
	<div
	className="loader-div"
  >
	<h1
	className="loader-title"
	>
	<div className="loader"><img src={Image.LogoWithoutName} /></div>
	<p className="loader-para">Launching into the depths of the universe.<br/> Patience, please</p>

	</h1>
  </div>
</section>
  )
}

export default React.memo(Loader)