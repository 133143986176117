import axios from 'axios';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import constants from '../constants';
import { useAuth } from './authContext';
import { useQueryClient } from '@tanstack/react-query';
interface Enroll {
_id: string;
title?: string;
active?:boolean;
isCertificateAvailable?: boolean;
isPaidInternshipAvailable?: boolean;
planType?: string
startDate?: string
step?: string
type?:string
groupLink?: string
activeDay?:number
totalDays?:number
updatedAt?:string
menu?: Array<string>
}

interface EnrollContextType {
  enroll: Enroll;
  updateEnroll: (newUserData: Enroll) => void;
}

// Create a new context
const EnrollContext = createContext<EnrollContextType | undefined>(undefined);

// Create a provider component to wrap your application
export const EnrollProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const [enroll, setEnroll] = useState<Enroll>({
    _id: '',
  });
  const {getToken} = useAuth()
  const refreshEnroll =async(userData:Enroll)=>{
    try{
    const token = await getToken()
    const res =await axios.get(`${constants.DOMAIN}/api/v1/enroll/refresh/${userData._id}`,{
      headers:{
        Authorization: 'Bearer ' + token
      }
    })
    let data = res.data.response
    setEnroll({
      _id:data?._id,
      active:data?.active,
      isCertificateAvailable:data?.isCertificateAvailable,
      planType:data?.planType,
      step:data?.step,
      type:data?.type,
      title:data?.title,
      groupLink:data?.groupLink,
      startDate:data?.startDate,
      isPaidInternshipAvailable:data?.isPaidInternshipAvailable,
      menu:data?.menu,
      updatedAt:new Date().toISOString()
     })
    }catch(err){
      console.log('Unable to Fetch Enroll');
      
    }
  }
  useEffect(() => {
    const userDataString = localStorage.getItem('cohort');
     if (userDataString) {
    const decrypt = atob(userDataString)
     const userData: Enroll = JSON.parse(decrypt);
    const currentDate = new Date();
    const previousDate = userData.updatedAt ? new Date(userData.updatedAt) : new Date()
    
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - previousDate.getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours
    //  console.log(userData);
     
    // console.log('called st',hoursDifference);
      if(userData._id ===''){
        if(window.location.pathname.includes('program/dashboard'))
          {
            window.location.href='/user/dashboard/course'
          }
      }
      else if(hoursDifference >=4)
      refreshEnroll(userData)
      else
      setEnroll(userData);
    }else{
      if(window.location.pathname.includes('program/dashboard'))
      {
        window.location.href='/user/dashboard/course'
      }
    }
  }, []);

  const refetch =async()=>{
    if(enroll.step === 'LMS' || enroll.step ==='ASSESSMENT' || enroll.step ==='INTERNSHIP' || enroll.step ==='DONE')
    await queryClient.refetchQueries({ queryKey: ['lms'], type: 'active' })
    
    if(enroll.step ==='ASSESSMENT' || enroll.step==='DONE' || enroll.step==='INTERNSHIP')
    await queryClient.refetchQueries({ queryKey: ['assessment'], type: 'active' })

    if(enroll.step==='INTERNSHIP' || enroll.step ==='DONE')
    await queryClient.refetchQueries({ queryKey: ['internship'], type: 'active' })

    if(enroll.step ==='DONE')
    await queryClient.refetchQueries({ queryKey: ['paidinternship'], type: 'active' })

} 
  useEffect(() => {
    if(enroll._id ==='')return
    refetch()
  }, [enroll._id])
  

  const updateEnroll = (newEnrollData: Enroll) => {
    setEnroll(newEnrollData);
    const encrypt = btoa(JSON.stringify(newEnrollData))
    localStorage.setItem('cohort', encrypt)
  };


  return (
    <EnrollContext.Provider value={{ enroll, updateEnroll }}>
      {children}
    </EnrollContext.Provider>
  );
};

// Custom hook to use the user context
export const useEnroll = () => {
  const context = useContext(EnrollContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
