 const constants = {
    DOMAIN:
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? "https://youvah.com"
        : process.env.REACT_APP_ENVIRONMENT==="development" ? "https://dev.youvah.com":"http://localhost:5001",
    OLD_DOMAIN:
        process.env.REACT_APP_ENVIRONMENT === "production"
          ? "https://app.youvah.com"
          : "http://localhost:5001",
    // GA_TRACKING_ID: "UA-196566323-1",
    GA_TRACKING_ID: "G-PH7NE0G1XG",
    GTM_TRACKING_ID: "AW-11391718451",
    PIXEL_TRACKING_ID: "1440600316745160",
    PIXEL_TRACKING_ID_2: "265665806218662",
    PIXEL_TRACKING_ID_3: "693115855190482",
    PIXEL_SNAPCHAT_TRACKING_ID: "796ebaaf-0bea-451a-b56c-443e67dea81d",
    PIXEL_EVENT: {
      LEAD: "Lead",
      PUCHASE: "Purchase",
    },
    singleBatchRestriction: new Set(["FD"]),
  }
  
export default constants