
const sizeMap = {
  small: 'w-max px-6 rounded-[3rem]',
  base: ' ',
  large: 'w-[40vh] rounded-[3rem]',
  xl: '',
  xxl: ' ',
  '3xl': ' ',
}

const variantMap = {
  primary:'bg-gradient-to-r from-primary-600 to-primary-700 ring-1 ring-primary-500 hover:ring-primary-400 ring',
  heroPrimary: 'disabled:cursor-not-allowed disabled:opacity-70  master-btn  text-white  outline-transparent font-semibold rounded-full border-transparent outline outline-offset-2 capitalize focus-visible:outline-primary-300 hover:outline-primary-300 outline-[1.5px] transition-all duration-150 ease-in-out',
  Slider:'rounded-lg master-btn text-center flex justify-center items-center w-36 h-9 p-3',
  SecondaryButton:'rounded-lg text-center border-2 border-solid border-brand flex justify-center items-center w-36 h-9 p-3'
}

type MasterBtnProps<T extends 'submit' | 'button'> = {
  text: string
  size: keyof typeof sizeMap
  variant?: keyof typeof variantMap
  type: T
  icon?: string
  isDisabled?: boolean
  onClick?: T extends 'submit' ? never : () => void,
  style?:string,
  id?:string
}

export function MasterBtn<T extends 'submit' | 'button'>({
  type,
  text,
  size,
  variant = 'primary',
  icon,
  style,
  isDisabled,
  id='',
  ...rest
}: MasterBtnProps<T>) {
  const sizeClass: string = sizeMap[size]
  const variantClass: string = variantMap[variant]
  return (
    <button
      disabled={isDisabled}
      {...rest}
      id={id}
      type={type === 'button' ? 'button' : 'submit'}
      className={`flex items-end capitalize transition-all text-[16px] font-youvah duration-150 ease-in-out tracking-wide rounded-[4px] gap-1.5 text-center justify-center p-1 py-3 px-3 font-extrabold text-[#fdfbff] ${sizeClass}  ${variantClass} ${style}`}
    >
      {text}

      {icon && (
        <div className="h-6 w-6 grid place-content-center pt-[1px] ">
          <img src={icon} loading="lazy" alt="icon for button"/>
        </div>
      )}
    </button>
  )
}
