import React from 'react'

interface InputProps {
    label: string;
    value: string;
    type: string;
    name: string;
    placeholder?: string;
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string | null |boolean;
    disabled?: boolean;
    required?: boolean;
    style?: string | null;
    bgColor?: string | null
  }

const Input = ({ label, value,type ='text',placeholder,name, onChange, error ,style,disabled,required,bgColor }: InputProps) =>{

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
      };
    
  return (
    <div className="mb-2">
      <label className="block text-[#fcfcfc] text-sm  mb-1" htmlFor={label}>
        {label}
      </label>
      <input
        className={`${bgColor?bgColor:'bg-[#1a2734]'} shadow appearance-none  border-1 rounded-lg w-full text-[16px] py-3 px-3 text-[#fcfcfc] leading-tight focus:outline-none focus:shadow-outline ${error ? 'border-red-500 ' : 'border-[#7b7b7b]'} ${style ? style : ''}`}
        id={label}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
      />
      {error && <p className="text-red-500 text-[14px] ml-1">{error}</p>}
    </div>
  )
}

export default Input 