import React, { useEffect, useRef, useState } from "react";

interface LazyImageProps {
  loadingText?: string;
  loadingClass?: string;
  loadingType?: "loader" | "text";
  src: string;
  alt?: string;
  className?: string; 
  style?: React.CSSProperties;
  lazy?:boolean;
  priority?:string;
  loadOnPageLoad?:boolean;
}

const MyImage: React.FC<LazyImageProps> = ({
  loadingText = "Loading...",
  loadingClass,
  loadingType = 'text',
  src,
  alt,
  className,
  style,
  lazy = false,
  loadOnPageLoad = false, 
  priority = 'high'
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState<string | undefined>(undefined);
  const placeholderRef = useRef<HTMLDivElement>(null); // Change the type to HTMLDivElement

  useEffect(() => {
    if (loadOnPageLoad) {
      setIsLoading(false);
      setView(src);
    } else {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) {
          setView(src);
          if (placeholderRef.current) {
            observer?.unobserve(placeholderRef.current); // Null check before unobserving
          }
        }
      });

      if (placeholderRef.current) {
        observer?.observe(placeholderRef.current);
      }

      return () => {
        if (placeholderRef.current) {
          observer?.unobserve(placeholderRef.current);
        }
      };
    }
  }, [src, loadOnPageLoad]);

  return (
    <>
      {isLoading && (
        <>
          {loadingType === 'text' && (
            <p ref={placeholderRef} className={`text-white ${loadingClass}`}>
              {loadingText}
            </p>
          )}
          {loadingType === 'loader' && (
            <span
              ref={placeholderRef}
              className={`loader-stripe overflow-hidden h-10 w-20 after:h-10 after:w-10 ${loadingClass}`}
            ></span>
          )}
        </>
      )}
      <img
        src={view} // Gets src only when placeholder intersects
        className={className}
        style={{ ...(isLoading ? { display: "none" } : {}), ...style }}
        alt={alt}
        loading={lazy ? "lazy" : "eager"}
        onLoad={() => setIsLoading(false)}
      />
    </>
  );
};

export default MyImage;
