import { useNavigate } from 'react-router-dom'
import { useUser } from 'Context/userContext'
import { useView } from 'Context/viewContext'

interface menuArr{
  title?: string
  id?: string
}
interface Sidebar {
  menuArr: Array<menuArr>
  isMenuActive: boolean,
  setIsMenuActive: any
}

export default function Sidebar_Home({menuArr,isMenuActive,setIsMenuActive}:Sidebar) {
  const navigate = useNavigate()
  const {user}=useUser()
  const {isMobile}=useView()
  return (
    <div className={`bg-[#21132c] absolute phone:hidden transition-all z-[9999] duration-500 ease-in-out h-full pt-8 ${isMenuActive? 'w-4/5' : 'w-0'} ' id="sidebar-desktop`}>
        <ul className='list-none overflow-hidden'>
          {menuArr.map((element, index) => {
            return (
              <li className={`${element.id === window.location.pathname  ? "bg-[#471770] rounded-sm sidebar_active" : ""}`} key={index}>
                <button  className='block font-semibold text-sm phone:text-base w-max mb-2 mt-2 text-white p-4' onClick={() => {
                   if(isMobile) setIsMenuActive(false)
                  if(element.id === 'dashboard')
                  navigate(user?.paymentStatus==='PAID'?'/user/dashboard/course':'/user/dashboard/all')
                  else if(element.id === 'hire')
                  window.open('https://youvah.fillout.com/t/qDeL9is5WAus','_blank');
                  else
                  navigate(`${element.id}`)
                }}>
                  <span className={element.id==="dashboard" || element.id==='signin'?"text-white border-white border-2 border-solid rounded-md px-7 py-2":""}>{element.title}</span>
                </button>
              </li>
            )
          })}
        </ul>
      </div>
  )
}


