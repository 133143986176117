import Image from 'Assets/index'
import { MasterBtn } from 'Components/buttons'
import MyImage from 'Components/MyImage'
import { useEnroll } from 'Context/enrollContext'
import React from 'react'
import "react-circular-progressbar/dist/styles.css"
interface LockPopProps{
	display: boolean
	setDisplay:React.Dispatch<React.SetStateAction<boolean>>;
  }
function LockPop({display,setDisplay}:LockPopProps) {
    const {enroll}=useEnroll()
    const handleModal =()=>{
        setDisplay(!display)
    }
	const nav: { [key: string]: string } = {
		LMS: 'Please complete your training to proceed with this step',
		ASSESSMENT: 'Please complete your challenge to proceed with this step',
		INTERNSHIP: 'Please complete your internship to proceed with this step',
		// DONE: 'Please complete your paid in to proceed with this step'
	  };
  return (
    <div id="pop-modal" className={`fixed ${display?'flex':'hidden'} justify-center items-center w-full inset-0 px-4 py-10 overflow-auto z-[9999] text-white`}>
	<div>
		<div
			className="modal max-w-lg mx-auto w-full bg-[#21132c] rounded-2xl py-3 md:py-4 lg:py-6 px-3 md:px-5 lg:px-7"
		>
			<div className="flex items-start justify-between gap-4">
				<div className="pt-1 flex flex-col gap-1">
				</div>
				<div className="pr-pt-px">
					<button
						type="button"
						onClick={handleModal}
						className="border-1 border-solid border-transparent focus-visible:bg-neutral-900
                    rounded-full hover:bg-neutral-900 transition-colors duration-300 ease-default focus-visible:border-white hover:border-opacity-40 hover:border-white outline-none focus-visible:outline-none p-1 md:p-2 h-8 w-8 md:h-10 md:w-10 grid place-content-center aspect-square"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
								fill="white"
							/>
						</svg>
					</button>
				</div>
			</div>

			<div className='flex w-full justify-center'>
			<MyImage src={Image.Pika} loadingText='' className='w-40'/>
			</div>
			<div>
			<section className="py-8 flex justify-center flex-col">
	<h6 className="text-center text-gray-300 font-bold">{nav[enroll.step || 'LMS']}</h6>
    <span className='w-full mt-4 flex justify-center'><MasterBtn size='small' type='button' text='Complete Level' variant='heroPrimary' onClick={
        ()=>handleModal()
    } /></span>
	{/* <h6 className="text-center text-white font-light"> Our <b>rockstar </b>team is gearing up to connect and share program details with you soon.<br />🌟 #Excited #StayTuned"</h6> */}
</section>	
			</div>
		</div>
	</div>
</div>
  )
}

export default LockPop
