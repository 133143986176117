import React from 'react';

interface ColoredTextProps {
  text: string;
  color?: string;
}

const ColoredText: React.FC<ColoredTextProps> = ({ text,color='#971eff' }) => {
  const parts = text.split('*');
  
  return (
    <span>
      {parts.map((part, index) => (
        <span dangerouslySetInnerHTML={{ __html: part }} className='w-max' key={index} style={{ color: index % 2 === 1 ? color : 'inherit' }}>
          {/* {part} */}
        </span>
      ))}
    </span>
  );
};

export default ColoredText

