import { useQuery } from '@tanstack/react-query';
import  constants  from '../constants';
import { useSocket } from 'Context/socketContext';
import React, { useEffect, useRef, useState } from 'react'
import { addNotificationToLocalStorage, getNotificationFromLocalStorage } from 'Utils/localStorageHandler';
import axios from 'axios';
import { useAuth } from 'Context/authContext';
import { useUser } from 'Context/userContext';
import ColoredText from './ColoredText';
import { MasterBtn } from './buttons';
// import { mixpanel } from 'Services/mixpanelAnalytics';

function Notification() {
    const [notify, setNotify] = useState<INotification[]>([])
    const [display, setDisplay] = useState<boolean>(false)
    const socket = useSocket();
    const {user} = useUser()
    const notificationIds = useRef<Set<string>>(new Set());    
    const watchedIds = useRef<Set<string>>(new Set(getNotificationFromLocalStorage()));    
    const {getToken}=useAuth()
    async function fetchData() {
        const token = await getToken();
        try {
          if (!token) throw new Error('Token is missing');
          const res = await axios.get(`${constants.DOMAIN}/api/v1/notify/getNotification`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // console.log(res)
          return res.data.data;
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      }
    
      const { data } = useQuery({
        queryKey: ['notification'],
        queryFn: fetchData,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchInterval: 1200000, 
    });
    function formatDistanceToNow(date: Date, options: { addSuffix?: boolean } = {}): string {
        const now = new Date();
        const elapsed = now.getTime() - date.getTime()
        
        const seconds = Math.floor(elapsed / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
    
        let result: string;
    
        if (seconds < 60) {
            result = `${seconds} seconds ago`;
        } else if (minutes < 60) {
            result = `${minutes} minutes ago`;
        } else if (hours < 24) {
            result = `${hours} hours ago`;
        } else {
            result = `${days} days ago`;
        }
    
        return options.addSuffix ? result : result.replace(' ago', '');
    }
    
      useEffect(() => {
        if (data) {
          data?.notifications.map((data:INotification)=>{
            if (!watchedIds.current.has(data._id) && !notificationIds.current.has(data._id) &&( user.notification?.includes(data?.segment)||user.notification?.includes(data?.recipient))) {
                notificationIds.current.add(data._id);
                setNotify((prev:any) => [...prev , data]);
              }
          })
        }
    }, [data]);

    useEffect(() => {
        console.log(notify);
        socket?.on('newNotification', (notification) => {
            if (!notificationIds.current.has(notification._id) && ( user.notification?.includes(notification?.segment)||user.notification?.includes(notification.recipient))) {
                notificationIds.current.add(notification._id);
                setNotify((prev:any) => [...prev , notification]);
              }
        });
    }, [socket]);


    const closeNotification = (id: string,expiry:number) => {
        setNotify((prev) => prev.filter((notification) => notification._id !== id));
        watchedIds.current.add(id);
        addNotificationToLocalStorage(id,expiry)
        // mixpanel.track("notification", {
        //     distinct_id: user._id,
        //     email: user.email,
        //     phone: user.phone,
        //     section: window.location.pathname,
        //     ip: sessionStorage.getItem('ip'),
        //   });
    };

   
  return (
<>
        
{!display&& <a href="#" onClick={()=>setDisplay(prev=>!prev)} className='font-bold  font-youvah'>
<div className="relative">
    <svg className="w-6 h-6 text-white animate-wiggle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M15.585 15.5H5.415A1.65 1.65 0 0 1 4 13a10.526 10.526 0 0 0 1.5-5.415V6.5a4 4 0 0 1 4-4h2a4 4 0 0 1 4 4v1.085c0 1.907.518 3.78 1.5 5.415a1.65 1.65 0 0 1-1.415 2.5zm1.915-11c-.267-.934-.6-1.6-1-2s-1.066-.733-2-1m-10.912 3c.209-.934.512-1.6.912-2s1.096-.733 2.088-1M13 17c-.667 1-1.5 1.5-2.5 1.5S8.667 18 8 17"/></svg>
    {notify.length >0 && <div className="h-4 w-4 bg-brand rounded-full flex items-center justify-center text-white text-[12px] absolute -top-3 -end-2">
        {notify.length}
        <div className="absolute top-0 start-0 rounded-full -z-10 animate-ping bg-brand w-full h-full" ></div>
    </div>}
</div>
    </a>}
       {display&& <div className="w-full  transition-all  duration-500 ease-in-out h-full bg-gray-800 bg-opacity-90 top-0 right-0 overflow-y-auto overflow-x-hidden z-[9999] fixed sticky-0" id="chec-div">
            <div className="w-full absolute z-10 right-0 h-full overflow-x-hidden transform translate-x-0 transition ease-in-out duration-700" id="notification">
                <div className="w-full phone:w-4/12 bg-[#14081e] h-screen overflow-y-auto p-8 absolute right-0">
                    <div className="flex mb-5 items-center justify-between">
                        <p tabIndex={0} className="focus:outline-none text-2xl font-semibold leading-6 text-white">Notifications</p>
                        <button role="button" aria-label="close modal" className="focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white rounded-md cursor-pointer" onClick={()=>setDisplay(prev=>!prev)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6 6L18 18" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                  

{notify.map((data:INotification)=>{
    return <div id="toast-default"
    className="flex items-center w-full mt-5 p-4 text-gray-500 bg-white rounded-lg shadow "
    role="alert">
    <div
        className="inline-flex self-start items-center justify-center flex-shrink-0 w-8 h-8 text-white-500 bg-white rounded-lg border-1 border-brand">
       <img width="25" height="25" src={data.imageUrl} alt="alarm--v1"/>
    </div>
    <div className="ml-3 text-black">
        <span className='font-bold ml-1'> <ColoredText text={data.message} color='#971eff'/> </span>
        <p className=" text-normal ml-1 leading-3 pt-1 text-gray-500">{formatDistanceToNow(new Date(data.createdAt), { addSuffix: true })}</p>
       {data.link&& <MasterBtn size='small' type='button' style='mt-4 px-1 py-1' onClick={()=>{window.open(data.link)}} text={data.ctaName || 'Check'} variant='Slider' />}
    </div>
    
    <button type="button" onClick={()=>closeNotification(data._id.toString(),new Date(data.expiresAt).getTime())} className="ml-auto self-start -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8  " data-collapse-toggle="toast-default" aria-label="Close">
    <span className="sr-only">Close</span>
    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
</button>
</div>
})}
                    

                    {notify.length>0 ? <div className="flex items-center justify-between">
                        <hr className="w-full"/>
                        <p tabIndex={0} className="focus:outline-none text-sm flex flex-shrink-0 leading-normal px-3 py-16 text-white">Thats it for now :)</p>
                        <hr className="w-full"/>
                    </div>:<div className="flex items-center justify-between">
                        <hr className="w-full"/>
                        <p tabIndex={0} className="focus:outline-none text-sm flex flex-shrink-0 leading-normal px-3 py-16 text-white">You are up to date :)</p>
                        <hr className="w-full"/>
                    </div>}
                </div>
            </div>
        </div>}
        </>
  )
}

export default React.memo(Notification)