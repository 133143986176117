const STORAGE_KEY = 'notification';

export const getNotificationFromLocalStorage =() =>{
  // Get the stored data
  const storedData = localStorage.getItem(STORAGE_KEY);
  let array: Array<{ value: string; expiry: number }> = [];

  if (storedData) {
    try {
      array = JSON.parse(storedData);
    } catch (error) {
      console.log('Error parsing stored data:', error);
    }
  }

  const now = new Date().getTime();
  
  // Filter out expired items and extract string values
  const cleanedArray = array
    .filter(item => item.expiry > now)
    .map(item => item.value);
  
  // Save the cleaned array back to localStorage
  const arrayToStore =  array
  .filter(item => item.expiry > now)
  
  localStorage.setItem(STORAGE_KEY, JSON.stringify(arrayToStore));

  return cleanedArray;
}

export const  addNotificationToLocalStorage=(newString: string , expiry:number)=> {
    // Get the current array from localStorage
    const storedData = localStorage.getItem(STORAGE_KEY);
    let array: Array<{ value: string; expiry: number }> = [];
  
    if (storedData) {
      try {
        array = JSON.parse(storedData);
      } catch (error) {
        console.log('Error parsing stored data:', error);
      }
    }
  
    const now = new Date().getTime();
  
    // Remove expired items
    array = array.filter(item => item.expiry > now);
  
    // Add the new string with an expiry date
    array.push({
      value: newString,
      expiry
    });
  
    // Save the updated array to localStorage
    localStorage.setItem(STORAGE_KEY, JSON.stringify(array));
  
  }
