import {  useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import  Image  from '../../Assets'
import MyImage from 'Components/MyImage'
import LockPop from './Lockpop'
import { useView } from 'Context/viewContext'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from 'Context/authContext'
import { useEnroll } from 'Context/enrollContext'
import constants from '../../constants';
import axios from 'axios'

interface menuArr{
  title?: string
  id?: string
  status?: string
}
interface Sidebar {
  menuArr: Array<menuArr>
  currentMenu: string | undefined
  setCurrentMenu: any
  isMenuActive: boolean,
  setIsMenuActive: any
}

export default function Sidebar_Program({menuArr, currentMenu,setCurrentMenu,isMenuActive,setIsMenuActive}:Sidebar) {
  const navigate = useNavigate()
  const [display, setDisplay] = useState<boolean>(false)
  const {isMobile}=useView()
  const {getToken}=useAuth()
  const {enroll}=useEnroll()
  const [quiz, setQuiz] = useState<any>(0);

  async function fetchData() {
    if(menuArr?.find(menuItem => menuItem.id === 'quiz' )?.id !== 'quiz')
    return 0

    const token = await getToken();
    try {
      if (!token) throw new Error('Token is missing');
      const res = await axios.get(`${constants.DOMAIN}/api/v1/enroll/quiz/status/${enroll._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let count:number = 0
      if(JSON.stringify(res.data.data.ongoing) !== JSON.stringify({}) ) {
        count += 1
      }
      // console.log(count);
      if(res.data.data.pending && res.data.data.pending.length > 0) {
        count += res.data.data.pending.length
      }
      // console.log(count);
      if(count >0){
        setQuiz(count)
      }      
      // console.log(res)
      return res.data.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  const { refetch } = useQuery({
    queryKey: ['quizUSer'],
    queryFn: fetchData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
});

useEffect(() => {
  refetch()  
}, []);

  return (
    <div className={`bg-[#21132c] absolute transition-all z-[9900] duration-500 ease-in-out md:relative tailwingSidebar phone:h-full pt-4  phone:pt-6 ${isMenuActive? 'w-4/5 md:w-72' : 'w-0'} ' id="sidebar-desktop`}>
      <div className={`absolute hidden md:block top-1/2 right-[-35px] z-50 ${isMenuActive?"":"transform rotate-540"} `} onClick={()=>setIsMenuActive(!isMenuActive)}><MyImage src={Image.Sidebar_Toogle} className={` ${isMenuActive?"":"transform transition-all rotate-180"} `} loadOnPageLoad={true} /> </div>
      <LockPop display={display} setDisplay={setDisplay}/>
        <ul className='list-none overflow-hidden'>
          {menuArr?.map((element, index) => {
            return (
              <li className={`${element.id === currentMenu ? "bg-[#471770] rounded-sm sidebar_active" :element.id ==='title'?'border-b-2 border-solid border-brand ':element.id==='dashboard'?` ${isMenuActive?"":"hidden"} border-t-2 w-full  border-solid border-brand absolute bottom-2 md:bottom-[5px]`:""}`} key={index}>
                <button  className='font-semibold items-center text-left text-sm phone:text-base w-full flex mb-2 mt-2 text-white p-4' onClick={() => {
                  if(isMobile) setIsMenuActive(false)
                  // if(element.id === 'title' || element.status ==='LOCKED'){
                  //   if(element.id!=='title')
                  //   setDisplay(true)
                  //   return
                  // }
                   if(element.id === 'title' ){
                    return
                  }
                  else if(element.id === 'dashboard')
                  navigate(`/user/dashboard/course`)
                  else
                  navigate(`/program/dashboard/${element.id}`)
                }}>
                  <span className={element.id === 'title'?'flex flex-wrap text-left w-full home_banner_text_gradient font-extrabold  font-youvah ':element.id==='dashboard'?'w-full text-[#b3b3b3]':'w-full'}>{element.id==="quiz"? <>{element.title } {
                     quiz > 0 && <span className=" text-[12px] font-bold me-2 px-1.5 py-1 rounded-full bg-green-700 text-white ml-1">{quiz > 1 ? quiz- 1 : quiz}+</span> }</>:element.title}</span>
                  {/* {element.status!=='NONE'&&<span className='flex items-center w-[20%] justify-center'>{images[element.status||'LOCKED']}</span>} */}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
  )
}


