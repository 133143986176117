import { ReactElement, useEffect, useState, useMemo } from 'react';
import {   useNavigate, useParams } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import Header_User from './Header/Header_User';
import Profilepop from './Profilepop';
import Sidebar_Program from './Sidebar/Sidebar_Program';
import { AllPrograms, CardDashboard, MyCourses, ProfileSetting, Resume, Lms, Challenge, Internships, 
  // PaidInternship,
   Lms_V1, 
   ReferAndEarn,
   PaidInternship,
   Quiz} from 'Pages/index';
import { useView } from 'Context/viewContext';
import { useEnroll } from 'Context/enrollContext';
import Header_Lms from './Header/Header_Lms';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'Context/authContext';
import { useUser } from 'Context/userContext';

interface MenuItem {
  title?: string;
  id?: string;
  element?: ReactElement;
  status?: string;
}

export default function DashboardWrapper(): ReactElement {
  const { menu } = useParams();
  const {currentUser}=useAuth()
  const {user} = useUser();
  const queryClient = useQueryClient();
  const { isMobile } = useView();
  const { enroll } = useEnroll();
  const [currentMenu, setCurrentMenu] = useState(menu);
  const [element, setElement] = useState<any>()
  const [isMenuActive, setIsMenuActive] = useState<boolean>(isMobile ? false : true);
  const isCourseDashboard = window.location.pathname.includes('program');
  const isLms = window.location.pathname.includes('learning');
  const navigate = useNavigate();
  const getSectionStatus = (section: string, step: string | undefined): string => {
    const statusMap: { [key: string]: { [key: string]: string } } = {
      assessment: {
        ASSESSMENT: 'UNLOCKED',
        INTERNSHIP: 'COMPLETED',
        DONE: 'COMPLETED',
        default: 'LOCKED'
      },
      internship: {
        INTERNSHIP: 'UNLOCKED',
        DONE: 'COMPLETED',
        default: 'LOCKED'
      },
      paid: {
        DONE: 'UNLOCKED',
        default: 'LOCKED'
      }
    };
    return statusMap[section]?.[step ?? 'default'] ?? 'LOCKED';
  };
const refetch =async()=>{
      // Use refetchQueries to trigger a refetch of specific queries
      if(!currentUser)return
      
      await queryClient.refetchQueries({ queryKey: ['courses'], type: 'active' })
      await queryClient.refetchQueries({ queryKey: ['updateProfile'], type: 'active' })
      await queryClient.refetchQueries({ queryKey: ['resume'], type: 'active' })
  
} 
useEffect(() => {
refetch()
}, [currentUser])

const menuArr: MenuItem[] = useMemo(() => {
  if (!isCourseDashboard) {
    const commonMenu = [
      { title: 'My Programs', id: 'course', element: <MyCourses /> },
      { title: 'My Resume', id: 'resume', element: <Resume /> },
      { title: 'Refer N Earn', id: 'referral', element: <ReferAndEarn /> },
      { title: 'Profile Settings', id: 'profile', element: <ProfileSetting /> },
    ];

    if(user.cardId){
      return [
        ...commonMenu,
        { title: 'My Card', id: 'card', element: <CardDashboard /> }
      ]
    }

    if (user.paymentStatus === 'PAID') {
      return [
        ...commonMenu,
        { title: 'Explore more Programs', id: 'all', element: <AllPrograms /> },
      ];
    } else {
      return [
        { title: 'All Programs', id: 'all', element: <AllPrograms /> },
        ...commonMenu,
      ];
    }
  } else {
    const finalMenu = enroll.menu?.map(item => {
      const status = getSectionStatus(item.toLowerCase(), enroll.step);
      switch (item) {
        case 'RESUME':
          return { title: 'My Resume', id: 'resume', element: <Resume />, status: 'UNLOCKED' };
        case 'CHALLENGE':
          return { title: 'My Challenge', id: 'assessment', element: <Challenge />, status };
        case 'QUIZ':
          return { title: 'My Quiz', id: 'quiz', element: <Quiz />, status: 'UNLOCKED' };
        case 'INTERNSHIP':
          return { title: 'My Internship', id: 'internship', element: <Internships />, status };
        case 'LMS_HYBRID':
        case 'LMS_LIVE':
          return { title: 'My Learning (LMS)', id: 'learning', element: item === 'LMS_HYBRID' ? <Lms /> : <Lms_V1 />, status: 'UNLOCKED' };
        case 'INTERNSHIP_PAID':
          return enroll.isPaidInternshipAvailable 
            ? { title: 'Paid Internship', id: 'alpha', element: <PaidInternship />, status } 
            : null;
        default:
          return null;
      }
    }).filter(item => item !== null) as MenuItem[];;

    if (enroll.title) {
      finalMenu?.unshift({ title: enroll.title, id: 'title', status: 'NONE' });
    }
    
    finalMenu?.push({ title: '< Back to Dashboard', id: 'dashboard', status: 'NONE' });
    return finalMenu;
  }
}, [isCourseDashboard, enroll.menu, user.paymentStatus, enroll.title, enroll.step]);


  useEffect(() => {
        setCurrentMenu(menu);
        setElement(menuArr?.find(menuItem => menuItem.id === menu )?.element)
  }, [menu,menuArr,isCourseDashboard]);

  useEffect(()=>{
    const enrollItem = JSON.parse(atob(localStorage.getItem('cohort') || "") || `{}`)
    if(isCourseDashboard && (!enrollItem.menu || enrollItem?.menu?.length ===0))
    return navigate('/user/dashboard/course')
  },[enroll.menu])
 
// console.log(menuArr);

  return (
    <div className="font-youvah overflow-hidden">
      <Profilepop />
      {isLms ? (
          <Header_Lms isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
        ) : (
          <Header_User isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
        )}
      <div className="h-screen w-full pt-[4.5rem] flex overflow-hidden">
        {isCourseDashboard ? (
          <Sidebar_Program currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} menuArr={menuArr} isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
        ) : (
          <Sidebar currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} menuArr={menuArr} isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
        )}
        <div className="w-full h-full pl-0 md:pl-10 bg-[#14081e] overflow-hidden overflow-y-scroll no-scrollbar">
          {element}
        </div>
      </div>
    </div>
  );
}
