import React from "react";
const Signin = React.lazy(() => import("./Authentication/Signin"));
const Home = React.lazy(() => import("./Home/index"));
const AllPrograms = React.lazy(() => import("./All Programs/index"));
const MyCourses = React.lazy(() => import("./My Courses/index"));
const Resume = React.lazy(() => import("./Resume/index"));
const ProfileSetting = React.lazy(() => import("./ProfileSetting/index"));
const CardDashboard = React.lazy(() => import("./MyCardDashboard/index"));
const Lms = React.lazy(() => import("./Lms/index"));
const Demo = React.lazy(() => import("./Demo/index"));
const Lms_V1 = React.lazy(() => import("./Lms/index_v1"));
const Challenge = React.lazy(() => import("./Challenge/index"));
const Internships = React.lazy(() => import("./Internships/index"));
const Certificate = React.lazy(() => import("./Certificates/index"));
const PaidInternship = React.lazy(() => import("./PaidInternship/index"));
const GeneralProgram = React.lazy(() => import("./GeneralProgram/index"));
const ProProgram = React.lazy(() => import("./ProProgram/index"));
const PaymentModal = React.lazy(() => import("./PaymentModal/index"));
const Track = React.lazy(() => import("./Track/index"));
const MyCardPublic = React.lazy(() => import("./MyCardPublic/index"));
const Aboutus = React.lazy(() => import("./Aboutus/index"));
const Partner = React.lazy(() => import("./Partner/index"));
const Policy = React.lazy(() => import("./Policy/index"));
const PaymentPage = React.lazy(() => import("./PaymentPage/index"));
const Courses = React.lazy(() => import("./Courses/index"))
const JobAssurance = React.lazy(() => import("./JobAssuranceProgram"));
const Lissun = React.lazy(() => import("./LissunLandingPage"));
const CopyWriting = React.lazy(() => import("./CopyWritingProgram"));
const Card = React.lazy(() => import("./Card"));
const FinanceProProgram = React.lazy(() => import("./FinanceProProgram"));
const AIProgram = React.lazy(() => import("./AIProgram"));
const ReferAndEarn = React.lazy(() => import("./ReferAndEarn"));
const ReferralProgram = React.lazy(()=>import("./ReferralProgram/index"))
const Quiz = React.lazy(()=>import("./Quiz/index"))


export {
 Signin,
 Home,
 AllPrograms,
 MyCourses,
 Resume,
 ProfileSetting,
 CardDashboard,
 Lms,
 Challenge,
 Internships,
 Certificate,
 PaidInternship,
 GeneralProgram,
 ProProgram,
 PaymentModal,
 Track,
 MyCardPublic,
 Aboutus,
 Policy,
 PaymentPage,
 Courses,
 Partner,
 JobAssurance,
 Lissun,
 Lms_V1,
 CopyWriting,
 Card,
 FinanceProProgram,
 ReferAndEarn,
 ReferralProgram,
 AIProgram,
 Quiz,
 Demo
};

// Creating Key value pair for Program Dashboard

export const ProgramDashboard = {
"RESUME" : Resume,
"LMS_HYBRID":Lms,
"LSM_LIVE":Lms_V1,
"CHALLENGE":Challenge,
"INTERNSHIP" : Internships,
"INTERNSHIP_PAID":PaidInternship,
"QUIZ":Quiz 
}