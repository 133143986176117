import React from "react";
import { MasterBtn } from "./buttons";
import { useLocation } from "react-router-dom";

export default function Component() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    // Extracting the parameters
    const type = queryParams.get("type");
    const program = queryParams.get("program");
    const amount = queryParams.get("amount");
  
  return (
    <React.Fragment>
      {/* Payment Successful Section */}
      <section className="w-full py-12 md:py-24 lg:py-32 bg-[#14081e] flex justify-center items-center">
        <div className="container px-4 md:px-6 flex flex-col md:flex-row items-center justify-between gap-8">
          <div className="space-y-4 text-center md:text-left">
            <div className="inline-block rounded-lg bg-green-100 px-3 py-1 text-sm font-medium text-green-600 dark:bg-green-900 dark:text-green-400">
              Payment Successful
            </div>
            <h1 className="text-3xl font-bold tracking-normal sm:text-4xl md:text-5xl text-white">
              Thank you for your purchase!
            </h1>
            <p className="max-w-[600px] text-gray-400 md:text-xl">
              Congratulations!!🎊 YouVah Membership UNLOCKED
            </p>
            <p className="max-w-[600px] text-gray-400 md:text-xl">
              Keep your INTERNnet on, We’ll get back to you soon!
            </p>
            <p className="max-w-[600px] text-gray-400 md:text-xl">
              <span className="font-bold text-white">Refer and earn</span> — Get guaranteed cashback on each successful
              referral, while your friend will also get a guaranteed discount!!
            </p>
            <p className="max-w-[600px] text-gray-400 md:text-xl">
              Fun Fact: <span className="font-bold text-white">You have unlimited referrals.</span>
            </p>
            <div className="flex justify-center items-center phone:justify-start gap-2 flex-row">
              <MasterBtn text='< Home' variant='SecondaryButton' size='small' style='font-normal' onClick={()=>{window.location.href ='/'}} type='button' />
        <MasterBtn text='Go to Dashboard' variant='Slider' size='small' style='font-normal' onClick={()=>{window.location.href ='/user/dashboard/course'}} type='button' />
              </div>
          </div>
          <img
            src="https://i.ibb.co/rGHsPPC/Payment-Success-Rate.jpg"
            width="400"
            height="400"
            alt="Payment Success"
            className="mx-auto aspect-square overflow-hidden object-contain rounded-2xl"
          />
        </div>
      </section>

      {/* Purchase Details Section */}
      {type && program && amount &&<section className="w-full py-12 md:py-24 lg:py-32 flex justify-center items-center bg-[#14081e]">
        <div className="container px-4 md:px-6">
          <div className="flex justify-center items-center">
            <div className="flex flex-col justify-center space-y-4">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-normal sm:text-5xl text-white">
                  Your Purchase Details
                </h2>
                <p className="max-w-[600px] text-gray-400 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Here are the details of your recent purchase.
                </p>
              </div>
              <div className="grid gap-4">
                <div className="flex items-center justify-between text-white">
                  <div className="font-medium">Program</div>
                  <div>{type}</div>
                </div>
                <div className="flex items-center justify-between text-white">
                  <div className="font-medium">Program Code</div>
                  <div>{program}</div>
                </div>
                <div className="flex items-center justify-between text-white">
                  <div className="font-medium">Total Amount Paid</div>
                  <div>{amount}</div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>}
    </React.Fragment>
  );
}
