import React, { useState, useContext, useEffect } from "react";

export const ViewportContext = React.createContext({
  width: 0,
  height: 0,
  isMobile: false,
});

export const useView = () => {
  return useContext(ViewportContext);
};

const ViewportProvider = ({ children }: any) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 900);

  const handleWindowResize = () => {
    const newWidth = window.innerWidth;
    const newIsMobile = newWidth <= 900;
    setWidth(newWidth);
    setHeight(window.innerHeight);
    setIsMobile(newIsMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ width, height, isMobile }}>
      {children}
    </ViewportContext.Provider>
  );
};

export default ViewportProvider;
