import  { useEffect } from 'react';
// import logo from './logo.svg';
import './App.css';
// import { getToken } from "firebase/messaging";
import ViewportProvider from './Context/viewContext';
import { AuthProvider } from './Context/authContext';
import Routers from './Routes';
import { EnrollProvider } from './Context/enrollContext';
import { HelmetProvider } from 'react-helmet-async';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { UserProvider } from 'Context/userContext';
import { gTag, initGA, initPixelConvTrkr, logPageView, logPixelPageView } from 'Utils/Analytics';
import { SocketProvider } from 'Context/socketContext';
// import { messaging } from 'Services/firebase';
function App() {

  // async function requestPermission() {
  //   try {
  //     const permission = await Notification.requestPermission();
  //     if (permission === "granted") {
  //       const token = await getToken(messaging, {
  //         vapidKey:process.env.REACT_APP_VAPID
  //       });
  //       console.log("Token Gen", token);
  //     } else if (permission === "denied") {
  //       console.warn("Notification permission denied");
  //     }
  //   } catch (error) {
  //     console.error("Error requesting notification permission", error);
  //   }
  // }


  useEffect(() => {
    // requestPermission()
    if (!window.GA_INITIALIZED) {
      initGA()
      initPixelConvTrkr()
      gTag()
      window.GA_INITIALIZED = true
    }
    logPageView()
    logPixelPageView()

    //IP
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => sessionStorage.setItem("ip",data.ip))
      .catch(error => console.error('Error fetching the IP address:', error));

	
    let event = sessionStorage.getItem('conversionApiEvent')
    
    if(!event)
    sessionStorage.setItem('conversionApiEvent',new Date().getTime().toString())
  
  //  setTimeout(()=>requestPermission(), 10000);
  if(window.location.origin?.toLocaleLowerCase().includes(process.env.REACT_APP_ENVIRONMENT==="local"?'localhost':'12thpass'))
    document.cookie = 'location' + "=IN; path=/";

  if(process.env.REACT_APP_ENVIRONMENT !== 'production')
    document.cookie = 'location' + "=IN; path=/";
  }, [])
  // console.log("environment",process.env.REACT_APP_ENVIRONMENT);
  //react Tanstack configuration
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchInterval: false,
        // gcTime:300000,
        // staleTime:300000,
        retry: 3,
        retryDelay: 1000,
      },
    },
  })
  
  return (
    <HelmetProvider>
    <div className='font-youvah'>
     <QueryClientProvider client={queryClient}>
    <AuthProvider>
    <SocketProvider roomId={process.env.REACT_APP_USER_ROOM!}>
      <UserProvider>
          <EnrollProvider>
      <ViewportProvider>
            <Routers />
      </ViewportProvider>
          </EnrollProvider>
      </UserProvider>
    </SocketProvider>
    </AuthProvider>
    </QueryClientProvider>
  </div>
  </HelmetProvider>
  );
}

export default App;
