import Image from 'Assets/index'
import React from 'react'

function DashboardLoader() {
  return (
    <div className=' w-full h-full flex justify-center items-center'>
        
      <div className="relative flex justify-center  items-center">
    <div className="absolute animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-purple-500"></div>
    <img src={Image.LogoWithoutName}  className="h-8 w-8"/>
</div>
    </div>
  )
}

export default React.memo(DashboardLoader)
