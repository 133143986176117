import React from 'react';
import Image from 'Assets/index';
import { useAuth } from 'Context/authContext';
import { Link, useNavigate } from 'react-router-dom';
import { useEnroll } from 'Context/enrollContext';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Notification from 'Components/Notification';

interface HeaderProps {
  isMenuActive: boolean;
  setIsMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header_Lms: React.FC<HeaderProps> = ({ isMenuActive, setIsMenuActive }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { enroll,updateEnroll } = useEnroll();

  return (
    <nav className="flex flex-row h-[4.5rem] absolute top-0 items-center justify-between z-[9999] w-full bg-[#14081e] pl-2 pr-4 md:pr-48">
      <div className=" md:w-48 h-[4rem] p-3">
        <span className='cursor-pointer'>
          <img onClick={() => navigate('/')} id="logoimgHeader" className='w-full h-full hidden md:block overflow-hidden' src={Image.logo} alt={"Logo"}/>
          <img onClick={() => setIsMenuActive(!isMenuActive)} width="25" height="25" className='block mt-1 md:hidden' src={isMenuActive ? `https://img.icons8.com/sf-black/64/FFFFFF/delete-sign.png` : `https://img.icons8.com/ios-filled/50/FFFFFF/menu--v6.png`} alt="menu--v6" />
        </span>
      </div>

      <div className="gap-3 justify-end items-center text-white flex flex-row md:w-[51rem]">
      <span className='shadow-custom-gray rounded-full px-2 py-2 '>
        <Notification/>
        </span>
        <span className='shadow-custom-gray rounded-xl px-2 py-1 flex gap-2 justify-center items-center'>
        
        <Link
          to={enroll.isCertificateAvailable ? `/certificate/training/${enroll._id}` : ""}
          style={{ width: "35px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          target={enroll.isCertificateAvailable ? "_blank" : ""}
        >
          <CircularProgressbarWithChildren
            value={enroll?.activeDay || 15}
            maxValue={enroll?.totalDays || 15}
            styles={buildStyles({
              strokeLinecap: "butt",
              textSize: "22px",
              pathTransitionDuration: 0.5,
              pathColor: `#971EFF`,
              textColor: "#FCFCFC",
              trailColor: "rgba(209, 156, 255, 0.3)",
            })}
          >
            <div style={{ position: "relative", display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="lms">
              <img src={Image.trophy} width={"20px"} alt="Trophy" />
              <div className="tooltip">
                {enroll.isCertificateAvailable ? 'Congratulations' :
                  enroll.planType?.includes('HYBRID') ? 'Complete the course to download Certificate' : 'Complete the challenge to download Certificate'}
              </div>
            </div>
          </CircularProgressbarWithChildren>
        </Link>
        <div className="dropdown mt-1">
          <button className="dropbtn">
            <img
              src={Image.dot}
              className='h-5 mt-1'
              alt="Dropdown Menu"
            />
          </button>
          <div className="dropdown-content">
            <a className='font-youvah text-black block font-bold'>Profile Settings</a>
            <a className='font-youvah text-black block font-bold' onClick={() => {
              localStorage.removeItem('user')
              localStorage.removeItem('cohort')
              localStorage.clear()
              updateEnroll({
                _id:''
              })
              logout()}}>Logout</a>
          </div>
        </div>
        </span>
       
      </div>
    </nav>
  );
}

export default Header_Lms;
