import { AxiosError, AxiosResponse } from "axios";
interface CustomAxiosError<T = any> extends AxiosError {
    response?: AxiosResponse<T>;
}
export function handleAxiosError(error: CustomAxiosError,setStatus?:React.Dispatch<React.SetStateAction<STATUS>>) {
    if (error.response) {
      if(setStatus)
      setStatus({ loading: false, success: false, error: error?.response?.data?.message ,section: false,sectionLoading: false })
      console.error('Server responded with status:', error.response.status);
      console.error('Server responded with Message', error?.response?.data?.message);
      console.error('Response data:', error.response.data);
    } else if (error.request) {
      if(setStatus)
      setStatus({ loading: false, success: false, error: "Something went wrong!",section: false,sectionLoading: false })
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
        if(setStatus)
      setStatus({ loading: false, success: false, error: "Something went wrong!",section: false,sectionLoading: false })
      console.error('Error setting up request:', error.message);
    }
    console.error('Error config:', error.config);
  }
  