import axios from 'axios'
import { MasterBtn } from 'Components/buttons'
import Input from 'Components/Input'
import { useUser } from 'Context/userContext'
import React, { useEffect, useState } from 'react'
import "react-circular-progressbar/dist/styles.css"
import constants from '../constants'
import { useAuth } from 'Context/authContext'
import DashboardLoader from 'Components/DashboardLoader'
import { handleAxiosError } from 'Utils/ErrorHandler'
type Validations ={
	school:boolean | string ,
	city:boolean | string ,
	standard:boolean | string ,
	dob:boolean | string ,
  }
  type Profile = {
    school: string;
    city: string;
    standard: string;
    dob: string;
  };
function Profilepop() {
    const {user,updateUser}=useUser()
    const {getToken}=useAuth()
	const [validations, setValidations] = useState<Validations>({
		school: false,
		city: false,
		standard: false,   
		dob: false,   
	  })
    const [profile, setProfile] = useState<Profile>({
      school:'',
      city:'',
      standard:'',
      dob:''
    })
	  const [status, setStatus] = useState<STATUS>({loading: false, error: false,success:false,section: false,sectionLoading: false})
    const [display, setDisplay] = useState<Boolean>(false)
	const [step, setStep] = useState<'PROFILE'|'UPDATE'>('PROFILE')
    const [count, setCount] = useState<number>(parseInt(localStorage.getItem('profileCount') || '0'))
    useEffect(() => {
     if(!user.isProfileCompleted && count < 1)
     setDisplay(!display)
    }, [user])
    
    const handleModal =()=>{
        if(display){
        localStorage.setItem('profileCount', (count + 1).toString())
        setCount(count+1)
         }
        setDisplay(!display)
    }
    const handleInputChange = (name: string, value: string) => {
      if(name === 'school'){
          setValidations({...validations, school: value === ""?'School is Required':false});
      }else if(name === 'standard'){
        setValidations({...validations, standard: value === "-- select --"?'Standard is Required':false});
    }else if(name === 'city'){
      setValidations({...validations, city: value === ""?'City is Required':false});
  }else if(name === 'dob'){
    setValidations({...validations, dob: value === ""?'Dob is Required':false});
  }
  
        setProfile((prevProfile) => ({
          ...prevProfile,
            [name]: value,
  
        }));
     
    };
    const handleSubmit = async() => {
      const { school, city, standard,dob } = profile;
      const newValidations = {
        school: school === ''?'School is Required':false,
        city: city === ''?'City is Required':false,
        standard: standard === '-- select --'?'Standard is Required':false,
        dob: dob === ''?'Dob is Required':false
      };
      setValidations(newValidations);
  
      if (!Object.values(newValidations).some((value) => value)) {
        try{
          setStatus({loading: true, error: false,success:false,section: false,sectionLoading: false})
    const token = await getToken()
    await axios.post(`${constants.DOMAIN}/api/v1/user/updateProfile`,{ profile},{
    headers:{
      Authorization: 'Bearer ' + token
    }
     })
     updateUser({...user,isProfileCompleted:true})
     setStatus({loading: false, error: false,success:false,section: false,sectionLoading: false})
     handleModal()
        }catch(error){
          if(axios.isAxiosError(error)){
            handleAxiosError(error,setStatus)
         }else{
           setStatus({
             loading: false,
             success: false,
             error:  "Something went wrong!",section: false,sectionLoading: false
           })
           console.log(error)
         }
        }
      }
    };
    if(status.loading)
    return <div id="pop-modal" className={`fixed ${display?'flex':'hidden'} justify-center items-center w-full inset-0 px-4 py-10 overflow-auto z-[9999] text-white`}>
    <div>
      <div
        className="modal max-w-lg mx-auto w-full bg-[#151618] rounded-2xl py-3 md:py-4 lg:py-6 px-3 md:px-5 lg:px-7"
      >
        <DashboardLoader />
      </div>
      </div>
      </div>

  return (
    <div id="pop-modal" className={`fixed ${display?'flex':'hidden'} justify-center items-center w-full inset-0 px-4 py-10 overflow-auto z-[9999] text-white`}>
	<div>
		<div
			className="modal max-w-lg mx-auto w-full bg-[#151618] rounded-2xl py-3 md:py-4 lg:py-6 px-3 md:px-5 lg:px-7"
		>
			<div className="flex items-start justify-between gap-4">
				<div className="pt-1 flex flex-col gap-1">
				</div>
				<div className="pr-pt-px">
					<button
						type="button"
						onClick={handleModal}
						className="border-1 border-solid border-transparent focus-visible:bg-neutral-900
                    rounded-full hover:bg-neutral-900 transition-colors duration-300 ease-default focus-visible:border-white hover:border-opacity-40 hover:border-white outline-none focus-visible:outline-none p-1 md:p-2 h-8 w-8 md:h-10 md:w-10 grid place-content-center aspect-square"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
								fill="white"
							/>
						</svg>
					</button>
				</div>
			</div>

			{step==='PROFILE'?<div>
			<section className="py-8 flex justify-center flex-col">
	<div className=" blade-bottom-padding-sm justify-center flex items-center flex-col gap-3">4
	<svg xmlns="http://www.w3.org/2000/svg" width="112" height="108" viewBox="0 0 112 108" fill="none">
<path d="M0.321186 18.1361C0.1438 15.277 2.31778 12.8168 5.1769 12.6409L82.1819 7.90382C85.041 7.72794 87.5026 9.90306 87.68 12.7621L92.9343 97.4492C93.1117 100.308 90.9377 102.769 88.0786 102.944L11.0736 107.681C8.2145 107.857 5.75293 105.682 5.57554 102.823L0.321186 18.1361Z" fill="#9FD7EF"/>
<path d="M12.9667 5.18669C12.9667 2.32216 15.2888 0 18.1534 0L95.3053 0C98.1699 0 100.492 2.32216 100.492 5.18669V90.0351C100.492 92.8996 98.1698 95.2218 95.3053 95.2218H18.1534C15.2888 95.2218 12.9667 92.8996 12.9667 90.0351V5.18669Z" fill="#FFFFFE"/>
<rect x="24.6367" y="39.7025" width="27.3922" height="4.19621" rx="2.09811" fill="#B3B3B3"/>
<rect x="24.6367" y="54.5508" width="27.3922" height="4.19621" rx="2.09811" fill="#B3B3B3"/>
<rect x="24.6367" y="47.1268" width="60.2952" height="4.19621" rx="2.09811" fill="#E5E5E5"/>
<path d="M28.7322 30.4439C28.5443 30.4753 28.4671 30.3356 28.3675 30.2444C24.1921 26.4199 23.4342 20.3716 26.5359 15.5954C27.2041 14.6705 27.9286 13.8235 28.8046 13.1122C30.5921 11.6609 32.6348 10.8194 34.9225 10.5643C35.2983 10.5224 35.6782 10.5173 36.085 10.4905C38.1111 10.5166 40.0139 10.9444 41.7498 11.9432C45.1002 13.8709 47.115 16.7396 47.5595 20.5825C47.998 24.3733 46.7198 27.5847 43.9559 30.2149C43.8532 30.3127 43.7697 30.4642 43.5661 30.4349C43.4064 30.3801 43.4367 30.2503 43.4155 30.1531C42.738 27.0481 40.2003 24.7117 37.0277 24.3544C34.8294 24.1069 32.861 24.7017 31.1869 26.1734C30.0148 27.2038 29.2432 28.4744 28.8919 29.9949C28.8579 30.1424 28.8325 30.2941 28.7322 30.4439Z" fill="#0172BD"/>
<path d="M28.7246 30.4793C29.1073 27.5742 31.5144 25.0356 34.4664 24.3945C38.5824 23.5006 42.5528 26.0046 43.4878 30.0845C43.5095 30.1795 43.5216 30.2767 43.5416 30.3991C43.4109 30.7784 43.0781 30.9258 42.799 31.1205C41.3863 32.106 39.8352 32.7664 38.1025 33.0555C38.0651 33.0676 38.0548 33.0782 38.0246 33.0984C37.9155 33.1113 37.8263 33.1146 37.7103 33.1184C37.6448 33.1215 37.6085 33.1315 37.5593 33.1625C37.3903 33.1749 37.238 33.1782 37.057 33.1817C36.9992 33.1842 36.9701 33.1862 36.9191 33.1896C36.6794 33.1925 36.4616 33.1939 36.2143 33.1967C36.0952 33.2618 36 33.2625 35.8766 33.2063C35.0081 33.1395 34.1794 33.0042 33.3489 32.8394C32.3486 32.6408 31.4432 32.235 30.5657 31.7548C29.9155 31.399 29.2718 31.0226 28.7246 30.4793Z" fill="#DBF1FF"/>
<path d="M32.6407 18.3606C32.4894 16.5552 33.6251 15.2444 34.8541 14.7657C36.2718 14.2136 37.8711 14.6109 38.8664 15.7718C39.8001 16.8609 39.9647 18.4271 39.2762 19.6702C38.5763 20.934 37.1161 21.6858 35.7435 21.4893C34.2178 21.2708 33.0281 20.2186 32.7141 18.8075C32.6836 18.6701 32.6665 18.5298 32.6407 18.3606Z" fill="#DBF1FF"/>
<path d="M29.8726 65.0413L100.494 68.1885L100.494 86.6029L29.013 83.2586L29.8726 65.0413Z" fill="#E8E2E1"/>
<rect x="25.0419" y="65.608" width="82.3386" height="17.427" rx="2.83647" fill="white" stroke="#E81F26" stroke-width="0.81042" stroke-dasharray="3.24 2.27"/>
<ellipse cx="101.465" cy="65.5253" rx="10.5355" ry="10.4905" fill="#E81F26"/>
<ellipse cx="101.464" cy="70.5288" rx="1.62084" ry="1.61393" fill="#FCF4F1"/>
<path d="M100.258 61.4026C100.224 60.6023 100.158 59.8785 100.169 59.1559C100.183 58.3218 100.616 57.94 101.445 57.9399C102.304 57.9399 102.751 58.2921 102.734 59.1279C102.681 61.5945 102.59 64.0607 102.476 66.5253C102.448 67.1346 102.249 67.7287 101.443 67.7033C100.639 67.678 100.502 67.0658 100.468 66.4634C100.374 64.8035 100.326 63.1411 100.258 61.4026Z" fill="#FCF4F1"/>
</svg>
		<h5 className="font-semibold">User Profile <span className='text-[#FB6900]'>Incomplete</span></h5>
        
	</div>
 
	<h6 className="text-center text-white font-light"><span className='text-2xl font-bold'>You are missing out </span> <br/>on personalised offers and exclusive opportunities. Please fill complete your profile to unlock the full benefits of YouVah!!</h6>
    <span className='w-full mt-4 flex justify-center'><MasterBtn size='small' type='button' text='Complete Profile' variant='heroPrimary' onClick={
        ()=>setStep('UPDATE')
    } /></span>
</section>	
			</div>: <div className='w-full flex flex-col gap-1 relative min-w-[360px]'>
      <Input
          label='School'
          type='text'
          name='school'
          value={profile.school}
          onChange={(e)=>handleInputChange('school',e.target.value)}
          style='bg-[#21132c]'
          error={validations.school}
        />
         <div className='mb-2'>
      <label className='block text-[#fcfcfc] text-sm mb-1' htmlFor='Select Class Studying in'>
         Standard
          </label>
         <select
        id="class"
        name="class"
        value={profile?.standard || ''}
        onChange={(e) => handleInputChange('standard',e.target.value)}
        required
        className='bg-[#21132c] shadow appearance-none border-1 rounded-lg w-full text-[16px] py-3 px-3 text-[#fcfcfc] leading-tight focus:outline-none focus:shadow-outline'
      >
        <option value="" hidden>
          -- select --
        </option>
        <option value="8th">Class 8th</option>
        <option value="9th">Class 9th</option>
        <option value="10th">Class 10th</option>
        <option value="11th">Class 11th</option>
        <option value="12th">Class 12th</option>
        <option value="1st">First Year</option>
        <option value="2nd">Second Year</option>
      </select>
      {validations.standard && <p className="text-red-500 text-[14px] ml-1 mt-2">{validations.standard}</p>}
        </div>
        <Input
          label='City'
          type='text'
          name='city'
          value={profile.city}
          onChange={(e)=>handleInputChange('city',e.target.value)}
          style='bg-[#21132c]'
          error={validations.city}
        />
        <Input
          label='Date of Birth'
          type='date'
          name='dob'
          value={profile.dob}
          onChange={(e)=>handleInputChange('dob',e.target.value)}
          style='bg-[#21132c]'
          error={validations.dob}
        />
        <div className='w-full flex justify-center'>
          <MasterBtn text='Update Profile' variant='Slider' size='small' style='font-normal' onClick={handleSubmit} type='button' />
        </div>
        {status.error && <p className="text-red-600  w-full  h-7 flex justify-center items-center absolute bottom-[-32px] text-[14px]  text-center">{status.error}</p>}
      {status.success && <p className="text-green-600  w-full  h-7 flex justify-center items-center absolute bottom-[-32px] text-[14px]  text-center">{status.success}</p>}

      </div>}
		</div>
	</div>
</div>
  )
}

export default React.memo(Profilepop)
