// QueryContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface QueryContextProps {
  query: string;
  setQuery: (newQuery: string) => void;
}

const QueryContext = createContext<QueryContextProps | undefined>(undefined);

export const QueryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [query, setQuery] = useState<string>(location.search);

  useEffect(() => {
    if (location.search) {
      setQuery(location.search); // Persist the query string on route change
    }
  }, [location]);
console.log(query);

  return (
    <QueryContext.Provider value={{ query, setQuery }}>
      {children}
    </QueryContext.Provider>
  );
};

 export const useQuery = () => {
  const context = useContext(QueryContext);
  if (!context) {
    throw new Error('useQuery must be used within a QueryProvider');
  }
  return context;
};

const Query=() => {
  const context = useContext(QueryContext);
  if (!context) {
    throw new Error('useQuery must be used within a QueryProvider');
  }
  return context;
};

export default Query