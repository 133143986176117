// import React from 'react'
import {  useNavigate } from 'react-router-dom'
import  Image  from '../../Assets'
import MyImage from 'Components/MyImage'
import { useView } from 'Context/viewContext'

interface menuArr{
  title?: string
  id?: string
}
interface Sidebar {
  menuArr: Array<menuArr>
  currentMenu: string | undefined
  setCurrentMenu: any
  isMenuActive: boolean,
  setIsMenuActive: any
}

export default function Sidebar({menuArr, currentMenu,setCurrentMenu,isMenuActive,setIsMenuActive}:Sidebar) {
  const navigate = useNavigate()
  const {isMobile}=useView()
  return (
    <div className={`bg-[#21132c] absolute transition-all z-50 duration-500 ease-in-out md:relative tailwingSidebar phone:h-full pt-8 ${isMenuActive? 'w-4/5 md:w-72' : 'w-0'}`} id="sidebar-desktop">
      <div className={`absolute hidden md:block top-1/2 right-[-35px] z-50 ${isMenuActive?"":"transform rotate-540"} `} onClick={()=>setIsMenuActive(!isMenuActive)}><MyImage src={Image.Sidebar_Toogle} className={` ${isMenuActive?"":"transform transition-all rotate-180"} `} loadOnPageLoad={true} /> </div>
        <ul className='list-none overflow-hidden'>
          {menuArr.map((element, index) => {
            return (
              <li className={`${element.id === currentMenu ? "bg-[#471770] rounded-sm sidebar_active" : ""}`} key={index}>
                <button  className='block font-semibold text-sm phone:text-base w-max mb-2 mt-2 text-white p-4' onClick={() => {
                   if(isMobile) setIsMenuActive(false)
                  navigate(`/user/dashboard/${element.id}`)
                }}>
                  <span className="item" style={{color:element.id==="alpha"?"":""}}>{element.title}</span>
                </button>
              </li>
            )
          })}
        </ul>
      </div>
  )
}


