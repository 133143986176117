import React, { useContext, useState, useEffect } from "react"
import { auth } from "../Services/firebase"

import {
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  signInWithCustomToken,
} from "firebase/auth"


const AuthContext = React.createContext({});

export function useAuth() {
  return useContext<any>(AuthContext)
}

export function AuthProvider({ children }:any) {
  const [currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState(true)

  function googleSignIn() {
    const provider = new GoogleAuthProvider()
    return signInWithPopup(auth, provider)
  }

  function getToken() {
    if (currentUser) {
      return currentUser.getIdToken(true);
    } else {
      return console.log("No user is currently signed in.");
    }
  }
  function signInCustomToken(token:string) {
    return signInWithCustomToken(auth, token)
  }

  function getAdditionalUserInfoGoogle(result:any) {
    return getAdditionalUserInfo(result)
  }

  function signup(email:string, password:string) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email:string, password:string) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email :string) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email:string) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password:string) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])


  return (
    <AuthContext.Provider value={ {
      currentUser,
      login,
      signup,
      logout,
      resetPassword,
      updateEmail,
      updatePassword,
      googleSignIn,
      getAdditionalUserInfoGoogle,
      signInCustomToken,
      getToken,
    }}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
