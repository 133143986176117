import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import 'firebase/compat/firestore';

let app;

console.log(process.env.REACT_APP_FIREBASE_API_KEY_DEV);
console.log(process.env.REACT_APP_ENVIRONMENT);

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_PROD_API_KEY,
    authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_PROD_APP_ID,
  });
} else {
  app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_DEV_APP_ID,
  });
}

export const auth = app.auth();
export default app;
