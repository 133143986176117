import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuth } from './authContext';

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

interface SocketProviderProps {
  roomId: string;
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ roomId, children }) => {
  const { currentUser } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);
  
  useEffect(() => {
    if (currentUser && !socket) {
      const newSocket = io(
        process.env.NODE_ENV === "production" ? 'https://youvah.com' : 'http://localhost:5001'
      );

      newSocket.emit('join_room', roomId);
      setSocket(newSocket);

      return () => {
        newSocket.emit('leave_room', roomId);
        newSocket.disconnect();
      };
    } else if (!currentUser && socket) {
      socket.emit('leave_room', roomId);
      socket.disconnect();
      setSocket(null);
    }
  }, [currentUser, roomId]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
