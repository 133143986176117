// Import necessary modules
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useAuth } from './authContext';

// Define the shape of your user context
interface User {
  _id: string;
  name: string;
  email: string;
  paymentStatus: string;
  isProfileCompleted: boolean;
  phone: string;
  cardId?:string
  notification?:string
  countryCode?:string
}

interface UserContextType {
  user: User;
  updateUser: (newUserData: User) => void;
}

// Create a new context
const UserContext = createContext<UserContextType | undefined>(undefined);

// Create a provider component to wrap your application
export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
const {logout} =useAuth()
  const [user, setUser] = useState<User>({
    _id: '',
    name: '',
    email: '',
    paymentStatus: '',
    isProfileCompleted: false,
    phone: '',
    notification:'',
    countryCode:'',
  });

  useEffect(() => {
    const userDataString = localStorage.getItem('user')
    if (userDataString) {
      const decrypt = decodeURIComponent(atob(userDataString))
      const userData: User = JSON.parse(decrypt);
      setUser(userData);
    }else{
      logout()
    }
  }, []);

  const updateUser = (newUserData: User) => {
    setUser(newUserData);
    const encrypt = btoa(JSON.stringify(newUserData))
    localStorage.setItem('user', encrypt)
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
